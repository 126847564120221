<template>
  <div class="container">
    <img src="@/assets/images/login_.png" alt="" />
    <div class="loginInfo">
      <p class="title">登录</p>
      <el-form
        :model="LoginUser"
        :rules="rules"
        status-icon
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <!-- 用户名 -->
        <el-form-item prop="name">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入用户名"
            v-model="LoginUser.name"
          >
          </el-input>
        </el-form-item>

        <!-- 用户密码 -->
        <el-form-item prop="pass">
          <el-input
            prefix-icon="el-icon-unlock"
            type="password"
            placeholder="请输入密码"
            v-model="LoginUser.pass"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <el-button @click="Login">立即登录</el-button>
      <p class="register"><span>忘记密码</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>免费注册</span></p>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return { LoginUser: {}, rules: {} };
  },
  mounted() {
    if (this.$route.path == "/login") {
      document.getElementById("app").style = "padding:0";
    } else {
      document.getElementById("app").style = "padding:71px 0 0";
    }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    // 点击登录触发
    Login() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate((valid) => {
        //如果通过校验开始登录
        if (valid) {
          // 发送ajax
          this.$axios
            .post("/api/front/login", {
              account: this.LoginUser.name,
              password: this.LoginUser.pass,
              // withCredentials: true,
            })
            .then((res) => {
              console.log("@@登录的响应：", res);
              // 200代表登录成功，其他的均为失败
              if (res.data.code == 200) {
                // res.data为后端响应的json
                sessionStorage.setItem("token", res.data.data.token);
                // sessionStorage.setItem("")
                this.getUserInfo();
              } else {
                //响应不是200
                // 清空输入框的校验状态
                this.$refs["ruleForm"].resetFields();
                // 弹出通知框提示登录失败信息
                this.notifyError(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              return Promise.reject(err);
            });
        } else {
          //未通过用户校验
          return false;
        }
      });
    },
    getUserInfo() {
      this.$axios
        .get("/api/front/user", {
          account: this.LoginUser.name,
          password: this.LoginUser.pass,
          // withCredentials: true,
        })
        .then((res) => {
          console.log(res, "fdsfsdfdsf");
          // 登录信息存到本地缓存
          let user = JSON.stringify(res.data.data);
          // 前端存储用户信息，表示登录成功
          sessionStorage.setItem("user", user);
          // 登录信息存到vuex,控制页面欢迎信息
          // console.log("@@res.data.user", res.data.user)
          this.setUser(res.data.data);
          // 弹出通知框提示登录成功信息
          this.notifySucceed(res.data.msg);
          this.$router.push("/");
        });
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  background: url("../assets/images/login-background.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  img {
    width: 508px;
    height: 380px;
  }
  .loginInfo {
    width: 440px;
    height: 380px;
    border-radius: 6px;
    background: #ffffff;
    margin-left: 200px;
    padding: 0 50px;
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      margin: 30px 0;
    }
    // .el-input {
    //   border-radius: 6px;
    //   background: rgba(63, 145, 247, 0.02);
    //   border: 1px solid rgba(0, 0, 0, 0.5);
    //   font-size: 18px;
    //   color: rgba(0, 0, 0, 0.5);
    //   line-height: 44px;
    //   padding: 0;
    //   margin-bottom: 30px;
    // }
    .el-button {
      width: 360px;
      height: 50px;
      background: #d8522c;
      border-radius: 6px 6px 6px 6px;
      font-size: 24px;
      color: #ffffff;
      font-weight: 500;
      line-height: 50px;
      padding: 0;
      border: none;
      margin-top: 20px;
    }
    .register {
      text-align: right;
      color: #d8522c;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
